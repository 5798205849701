import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Ласкаво просимо до TileMaster Services
			</title>
			<meta name={"description"} content={"Експертна майстерність у кожній плитці"} />
			<meta property={"og:title"} content={"Ласкаво просимо до TileMaster Services"} />
			<meta property={"og:description"} content={"Експертна майстерність у кожній плитці"} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/1-2.jpg?v=2024-06-03T13:33:16.422Z"} />
		</Helmet>
		<Components.Header />
		<Components.Hero background="linear-gradient(0deg,rgba(4, 16, 41, 0.74) 0%,rgba(4, 16, 41, 0.79) 100%),rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-1.jpg?v=2024-06-03T13:33:16.438Z) center/cover repeat scroll padding-box">
			<Override slot="link" display="none" />
			<Override slot="text">
				Наші Послуги
			</Override>
			<Override slot="text1" font="--lead">
				У TileMaster Services ми розуміємо, що правильна плитка може перетворити простір зі звичайного на надзвичайний. Ось чому ми пропонуємо повний спектр послуг з укладання плитки, розроблений для задоволення унікальних потреб кожного клієнта. Від початкової консультації до останнього штриха ми гарантуємо, що кожен проект відображає наше прагнення до якості та майстерності.
			</Override>
		</Components.Hero>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13:33:16.455Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13%3A33%3A16.455Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13%3A33%3A16.455Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13%3A33%3A16.455Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13%3A33%3A16.455Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13%3A33%3A16.455Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13%3A33%3A16.455Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-3.jpg?v=2024-06-03T13%3A33%3A16.455Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 0px 0px 90px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
				>
					<Text margin="0px 0px 10px 0px" color="#564aec" font="normal 400 20px/1.5 --fontFamily-sansHelvetica">
						TileMaster Services пишається тим, що надає різноманітні рішення для плитки, адаптовані як до естетики, так і до функціональності. Ось більш детальний огляд наших спеціалізованих пропозицій:
					</Text>
					<Text margin="0px 0px 30px 0px" color="--dark" font="normal 700 42px/1.2 --fontFamily-sans">
						Що ми пропонуємо
					</Text>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Плитка для житлових будинків:
Кухні: ми створюємо декоративні панелі та підлоги, які є одночасно стильними та функціональними.
Ванні кімнати: водонепроникна та протиковзаюча плитка, ідеально підходить для вологих середовищ.
Житлові зони: чудово виготовлена ​​плитка, яка покращує загальний вигляд і відчуття вашого дому.
					</Text>
					<Text margin="2rem 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Плитка для житлових будинків:
Кухні: ми створюємо декоративні панелі та підлоги, які є одночасно стильними та функціональними.
Ванні кімнати: водонепроникна та протиковзаюча плитка, ідеально підходить для вологих середовищ.
Житлові зони: чудово виготовлена ​​плитка, яка покращує загальний вигляд і відчуття вашого дому.
					</Text>
					<Text margin="2rem 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Плитка для житлових будинків:
Кухні: ми створюємо декоративні панелі та підлоги, які є одночасно стильними та функціональними.
Ванні кімнати: водонепроникна та протиковзаюча плитка, ідеально підходить для вологих середовищ.
Житлові зони: чудово виготовлена ​​плитка, яка покращує загальний вигляд і відчуття вашого дому.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="40px 0 40px 0" quarkly-title="Statistics-7" height="100%">
			<Box min-width="100px" min-height="100px" display="flex" md-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					md-width="100%"
					md-margin="0px 0px 50px 0px"
					sm-margin="0px 0px 35px 0px"
				>
					<Image
						src="https://uploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13:33:16.439Z"
						display="block"
						width="auto"
						height="100%"
						max-width="100%"
						object-fit="cover"
						object-position="30% 50%"
						md-height="600px"
						sm-height="400px"
						srcSet="https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13%3A33%3A16.439Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13%3A33%3A16.439Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13%3A33%3A16.439Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13%3A33%3A16.439Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13%3A33%3A16.439Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13%3A33%3A16.439Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665dc586e8900d0022f2f77f/images/2-2.jpg?v=2024-06-03T13%3A33%3A16.439Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					padding="0px 90px 0px 0px"
					display="flex"
					flex-direction="column"
					justify-content="center"
					lg-padding="0px 0px 0px 50px"
					md-width="100%"
					md-padding="0px 35px 80px 35px"
					sm-padding="0px 0 80px 0"
					order="-1"
				>
					<Text margin="0px 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Комерційна плитка:
Корпоративні офіси: міцна та витончена плитка, яка покращує естетику робочого місця.
Торгові площі: індивідуальний дизайн, який узгоджується з ідентичністю бренду та залученням клієнтів.
Гостинність: надійні та елегантні рішення для облицювання плиткою для місць з інтенсивним рухом людей у ​​готелях і ресторанах.
					</Text>
					<Text margin="2rem 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Спеціалізовані послуги з укладання плитки:
Індивідуальний дизайн плитки: індивідуальні візерунки та кольори відповідно до вашого бачення.
Ремонт і технічне обслуговування: забезпечення довговічності та бездоганного стану ваших установок.
Екологічно чисті рішення: Екологічно чисті матеріали для сталого будівництва.
					</Text>
					<Text margin="2rem 0px 0 0px" color="--dark" font="--headline3">
						Відкрийте більше
					</Text>
					<Text margin="1rem 0px 0px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" color="#75767d">
						Ці основні моменти – лише початок того, що може запропонувати TileMaster Services. Ми запрошуємо вас відчути всю широту наших можливостей. Зв’яжіться з нами, щоб дізнатися, як ми можемо пристосувати наші послуги до ваших конкретних потреб, або відвідайте наш заклад, щоб переконатися в якості нашої роботи з перших вуст. Наші експерти з радістю розкажуть вам про наше обширне портфоліо та допоможуть зробити найкращий вибір для вашого простору.
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"661cef388e2e8e00217acf89"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});